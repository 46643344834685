import React from "react";

function LeftMsg({ textMsg }) {
  return (
    <div className="w-full">
      <div className={`${textMsg.length === 0 && 'hidden'} w-3/4 bg-[#F4F0F0] text-[#3C002D] rounded-xl rounded-bl-none p-8 mb-8 whitespace-pre-line font-degular`}>
        {textMsg}
      </div>
    </div>
  );
}

export default LeftMsg;
