/* eslint-disable no-unused-vars */
import Button from "./ui/Button";
import RightMsg from "./ui/RightMsg";
import LeftMsg from "./ui/LeftMsg";
import { useRef, useState, useEffect, Fragment } from "react";
import { openAIStream } from "./utils/api";
import OrkesterLogo from "./orkester.svg";
import OpenAILogo from "./openai.svg";
import Helper from "./helper.svg";

function App() {
  const [messages, setMessages] = useState([]);
  const [userMsg, setUserMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const chatRef = useRef(null);

  const goToChatBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTop =
        chatRef.current.scrollHeight - chatRef.current.clientHeight;
    }
  };

  const handleInit = async () => {
    const newMessages = [
      {role: "user", content: "Je cherche un itinéraire touristique à Paris"},
      {role: "assistant", content: ""}
    ]
    setMessages(newMessages)
  };

  const handleSend = async () => {
    if (userMsg) {
      setMessages((prevState) => [
        ...prevState,
        { content: userMsg, role: "user" },
        {role: "assistant", content: ""}
      ])
      setUserMsg("")
    }
  };

  useEffect(() => {
    if (messages.length > 0 && messages[messages.length - 1].role === "assistant" && messages[messages.length - 1].content === "") {
      setLoading(true);
      openAIStream(messages, setMessages);
      setLoading(false)
    }
  }, [messages])

  useEffect(() => {
    goToChatBottom();
  }, [messages]);

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      handleSend()
    }
  }

  return (
    <div className="bg-[#F4F0F0] w-screen h-screen max-h-screen">
      <header className="bg-[#FFF] w-full h-20 flex justify-between items-center px-20 text-[#3C002D]">
        <h1 className="text-4xl font-degular">
          POC n°2 - Trouver un itinéraire
        </h1>
        <div className="flex items-center">
          <img src={OrkesterLogo} alt="My SVG" />
          <p className="mx-2 font-bold">X</p>
          <img src={OpenAILogo} alt="My SVG" />
        </div>
      </header>
      <main className="h-[46rem] max-h-[80%] w-screen bg-[#F4F0F0]">
        <div className="bg-[#FFF] h-full w-full max-w-4xl mx-auto mt-8 rounded-b-[44px] rounded-t-[60px] flex flex-col justify-between">
          {messages.length === 0 ? (
            <Fragment>
              <div className="h-[85%] border-b-[1px] border-b-[#DDD8D8] flex flex-col justify-center items-center px-8">
                <img src={Helper} alt="un gentil policier" className="mb-8" />
                <h2 className="font-degular text-3xl text-[#3C002D]">
                  Besoin d'aide pour planifier un itinéraire ?
                </h2>
              </div>
              <Button
                buttonText={loading ? "Chargement..." : "Lancer la recherche"}
                disabled={loading}
                onClickAction={() => handleInit()}
              />
            </Fragment>
          ) : (
            <Fragment>
              <div
                className="p-8 pb-0 flex flex-col overflow-scroll border-b-[1px] border-b-[#DDD8D8] h-[85%]"
                ref={chatRef}
                id="no-scrollbar"
              >
                {messages.map((msg) => {
                  if (msg.role === "user") {
                    return (
                      <RightMsg
                        textMsg={msg.content}
                        key={msg.id}
                      />
                    );
                  } else {
                    return (
                      <LeftMsg
                        textMsg={msg.content}
                        key={msg.id}
                      />
                    );
                  }
                })}
                {loading && (
                  <div class="flex space-x-2 justify-center items-center bg-white dark:invert h-10 w-28">
                    <span class="sr-only">Loading...</span>
                    <div class="h-3 w-3 bg-[black] rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div class="h-3 w-3 bg-[black] rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div class="h-3 w-3 bg-[black] rounded-full animate-bounce"></div>
                  </div>
                )}
              </div>
              <div className="m-8 text-[#3C002D] bg-[#F4F0F0] px-2 flex items-center rounded-md">
                <textarea
                  value={userMsg}
                  type="text"
                  className="w-full bg-[#F4F0F0] font-medium font-degular m-4 h-6 rounded-xl resize-none flex items-center justify-center mr-2 focus-visible:outline-none"
                  onChange={(event) => setUserMsg(event.target.value)}
                  onKeyDown={(event) => handleEnter(event)} 
                />
                <div
                  className={`${userMsg ? "bg-[#3C002D]" : "bg-[#917E8C]"} text-[#FFF] rounded-md w-7 h-7 flex justify-center items-center cursor-pointer`}
                  onClick={() => handleSend()}
                >
                  <svg
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 6L6 1L11 6M6 13V2"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </main>
    </div>
  );
}

export default App;
