import axios from "axios";

//const url = process.env.REACT_APP_API_URL;
const url = 'http://localhost:8080'

/**
 * @returns {Promise<{runId: string; threadId: string}>}
 */
export const initThread = async () => {
  const response = await axios.post(`${url}/itinerary`, {
    text: "J'aimerais visiter Paris",
  });
  return response.data;
};

export const getMessages = async (threadId) => {
  const response = await axios.get(`${url}/itinerary/messages/${threadId}`);
  return response.data;
};

export const sendNewMessage = async (threadId, userMsg) => {
  const response = await axios.post(`${url}/itinerary/messages/${threadId}`, {
    text: userMsg,
  });
  console.log(response);
  return response.data;
};

export const openAIStream = async (messages, setMessages) => {
  const response = await fetch(`${url}/streaming`, {
    method: 'POST',
    body: JSON.stringify({
      messages: messages
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const reader = response.body.getReader();
  const decoder = new TextDecoder();

  const readStream = async () => {
    try {
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          console.log('Streaming ended');
          break;
        }
        const chunk = decoder.decode(value);
        await setMessages(prevMessages => {
          const updatedMessages = [...prevMessages]
          updatedMessages[updatedMessages.length - 1] = {
            ...updatedMessages[updatedMessages.length - 1], // Preserve other properties
            content: updatedMessages[updatedMessages.length - 1].content + chunk
          };
          return updatedMessages
        });
      }
    } catch (error) {
      console.error('Error reading stream:', error);
    }
  };

  readStream();
}