import React from "react";

function RightMsg({ textMsg }) {
  return (
    <div className="w-full">
      <div className="w-3/4 bg-bg-secondary text-[#3C002D] border border-[#DDD8D8] rounded-xl rounded-br-none p-8 mb-8 ml-auto whitespace-pre-line font-degular">
        {textMsg}
      </div>
    </div>
  );
}

export default RightMsg;
