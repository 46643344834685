import React from "react";

function Button({ buttonText, onClickAction, disabled }) {
  return (
    <div className="flex m-8">
      <button
        disabled={disabled}
        className={`w-full py-2 rounded-xl uppercase text-md text-txt-secondary font-spaceMono ${disabled ? "bg-[#3C002D] animate-pulse" : "bg-[#FF3200] hover:bg-[#E02D00]"}`}
        onClick={onClickAction}
      >
        {buttonText}
      </button>
    </div>
  );
}

export default Button;
